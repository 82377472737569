<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <v-card-title class="headline">{{ provider | titleize }} config for {{org.name}}</v-card-title>

      <v-card-text>      
        <p>
          Bring your own OAuth Client to your organization. This allows you to have your own API limits and customize the description and icon during the connect flow.
        </p>
      </v-card-text>

      <v-alert v-if="loadedClientId" type="success" tile>This app is registered correctly.</v-alert>

      <v-card-text>
        <v-text-field v-model="model.client_id" label="Client ID" />
        <v-text-field v-model="model.client_secret" label="Client Secret" />
        <v-btn color="primary" large :disabled="!valid" @click="save">{{ loadedClientId ? 'Update' : 'Save' }}</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'

export default {
  name: "OAuthClient",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      provider: null,
      model: {},
      loadedClientId: false,
      siteData: siteData,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getOrg(id, provider) {
      this.provider = provider;
      const client = (await orgManagerService.getOAuthClient(id, provider)).data || {};

      this.loadedClientId = client.client_id;
      this.model = { client_id: client.client_id };
      console.log('model', this.model);
    },

    async getProfile() {
      if (this.user) {
        await this.getOrg(this.$route.params.id, this.$route.params.provider);
      }
    },

    async save() {
      console.log('Saving', this.model);
      const response = (await orgManagerService.putOAuthClient(this.org.id, this.provider, this.model)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved client registration.');
      await this.getProfile();
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.org || !this.provider ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Integrations', disabled: true },
        { text: `${this.provider} client registration`, disabled: true },
      ];
    },
    valid() {
      return this.model.client_id && this.model.client_secret;
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
</style>

